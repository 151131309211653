import React, { useState, useEffect } from "react";
import styles from "@/styles/components/Toast.module.css";
import Button from "./Button";
import cn from "@/utils/cn";

function Toast({ children, title, show }) {
  const [isShown, setIsShown] = useState(show);

  useEffect(() => {
    setIsShown(show);
  }, [show]);

  function handleCloseClick() {
    setIsShown(false);
  }

  return isShown ? (
    <div className={cn(styles.toast, isShown ? styles.show : styles.hide)}>
      <div className="flex justify-center border-b-[1px] border-white bg-gray-900 p-[0.25rem_0.75rem] text-white">
        <span className="m-0 leading-10">{title}</span>
        <Button
          additionalStyles={styles.toastButton}
          onClick={handleCloseClick}
          data-dismiss="toast"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </Button>
      </div>
      <div className={cn(styles.toastBody, "bg-gray-900")}>{children}</div>
    </div>
  ) : null;
}

export default Toast;
