import Button from "./Button";

export default function HelpButton({ children, className, ...props }) {
  return (
    <>
      <div
        id="helpDialog"
        className="fixed left-[50%] top-[50%] z-50 flex h-[50%] w-[50%] translate-x-[-50%] translate-y-[-50%] items-center justify-center bg-black bg-opacity-50 backdrop-blur"
        style={{ display: "none" }}
      >
        <div className="p-8">
          <div>
            <Button
              additionalStyles="fixed h-10 w-10 top-[1rem] right-[1rem]"
              onClick={() =>
                (document.querySelector("#helpDialog").style.display = "none")
              }
            >
              X
            </Button>
          </div>
          <div className="m-8">{children}</div>
          <div>
            <Button
              additionalStyles="fixed bottom-[1rem] right-[1rem]"
              onClick={() =>
                (document.querySelector("#helpDialog").style.display = "none")
              }
            >
              Close
            </Button>
          </div>
        </div>
      </div>
      <Button
        additionalStyles="bg-orange-800 text-white hover:bg-orange-700 focus:ring-orange-500 focus:ring-opacity-50 focus:outline-none focus:ring-2"
        {...props}
        onClick={() =>
          (document.querySelector("#helpDialog").style.display = "block")
        }
      >
        Help
      </Button>
    </>
  );
}
