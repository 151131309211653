import Head from "next/head";
import { useState, useEffect, useContext } from "react";
import { WASMContext } from "@/providers/WASM";
import Button from "@/components/Button";
import HelpButton from "@/components/HelpButton";
import CopyButton from "@/components/CopyButton";

export default function Home() {
  const ctx = useContext(WASMContext);
  const [pattern, setPattern] = useState("");
  const [output, setOutput] = useState([]);
  const [length, setLength] = useState(10);
  const [numPonies, setNumPonies] = useState(1);
  const [includeAlternative, setIncludeAlternative] = useState(false);
  const [includeAlternativeEnabled, setIncludeAlternativeEnabled] =
    useState(true);
  const [onlyAlternative, setOnlyAlternative] = useState(false);
  const [sinful, setSinful] = useState(false);

  const [bias, setBias] = useState(false);
  const [biasDirection, setBiasDirection] = useState(false);
  const [biasSwitchDirection, setBiasSwitchDirection] = useState(false);
  const [biasAdjacent, setBiasAdjacent] = useState(false);

  useEffect(() => {
    if (onlyAlternative) {
      setIncludeAlternative(false);
      setIncludeAlternativeEnabled(false);
    } else {
      setIncludeAlternativeEnabled(true);
    }
  }, [includeAlternative, onlyAlternative]);

  useEffect(() => {
    if (!bias) {
      setBiasDirection(false);
      setBiasSwitchDirection(false);
      setBiasAdjacent(false);
    }
    if (!biasDirection) {
      setBiasSwitchDirection(false);
    }
  }, [bias, biasDirection]);

  useEffect(() => {
    if (numPonies > 4 && !sinful) {
      setNumPonies(4);
    } else if (numPonies > 8 && sinful) {
      setNumPonies(8);
    }
  }, [numPonies, sinful]);

  useEffect(() => {
    if (!ctx.wasm) return;
    setPattern(
      ctx.wasm.generate(
        length,
        sinful,
        bias,
        biasDirection,
        biasSwitchDirection,
        biasAdjacent,
      ),
    );
  }, [
    length,
    sinful,
    ctx,
    bias,
    biasDirection,
    biasSwitchDirection,
    biasAdjacent,
  ]);

  useEffect(() => {
    if (!ctx.wasm) return;
    const patternList = [];
    const includePrefix = numPonies > 1;
    for (let i = 0; i < numPonies; i++) {
      let mirrorPattern;
      if (sinful) {
        mirrorPattern = ctx.wasm.mirror_sinful(pattern, i + 1);
      } else {
        mirrorPattern = ctx.wasm.mirror_std(pattern, i + 1);
      }
      const alternative = ctx.wasm.conventional_to_alternative(mirrorPattern);
      patternList[i] = `${includePrefix ? `${i + 1}: ` : ""}${mirrorPattern}`;
      if (includeAlternative) {
        patternList[i] += ` (${alternative})`;
      }
      if (onlyAlternative) {
        patternList[i] = `${includePrefix ? `${i + 1}: ` : ""}${alternative}`;
      }
    }
    setOutput(patternList);
  }, [pattern, includeAlternative, onlyAlternative, numPonies, ctx, sinful]);

  function generatePattern() {
    setPattern(
      ctx.wasm.generate(
        length,
        sinful,
        bias,
        biasDirection,
        biasSwitchDirection,
        biasAdjacent,
      ),
    );
  }

  function handleLengthChange(event) {
    const value = event.target.value;
    const intValue = Number.parseInt(value.replace(/[^\d]/g, ""));
    if (!Number.isNaN(intValue) && intValue > 0) {
      setLength(intValue);
    }
  }

  function handleNumPoniesChange(event) {
    if (event.target.value > 0 && event.target.value < 5)
      setNumPonies(event.target.value);
  }

  function handleNumPoniesIncrement() {
    if (!sinful) {
      if (numPonies < 4) setNumPonies(numPonies + 1);
    } else {
      if (numPonies < 8) setNumPonies(numPonies + 1);
    }
  }

  function handleNumPoniesDecrement() {
    if (numPonies > 1) setNumPonies(numPonies - 1);
  }

  function toggleAdvanced() {
    const advanced = document.getElementById("advanced");
    advanced.style.display =
      advanced.style.display === "none" ? "block" : "none";
  }

  return (
    <>
      <Head>
        <title>Flower Generator</title>
        <meta name="description" content="Flower Generator" />
      </Head>
      <main>
        <h1 className="m-2 p-2 text-xl">Generator</h1>
        <input
          className="m-2 rounded bg-gray-700 p-2"
          type="number"
          value={length}
          onChange={handleLengthChange}
          data-attr="length"
        />
        <Button
          additionalStyles=""
          onClick={generatePattern}
          data-attr="generate"
        >
          Generate
        </Button>
        <Button
          additionalStyles="bg-yellow-600 hover:bg-yellow-500 text-white focus:ring-yellow-500 focus:ring-opacity-50 focus:outline-none focus:ring-2"
          onClick={toggleAdvanced}
          data-attr="advanced"
        >
          Advanced
        </Button>
        <CopyButton
          copyText={output.reduce((acc, curr) => `${acc + curr}\n`, "")}
          data-attr="copy"
        />
        <HelpButton data-attr="help">
          <p>
            Enter the length of pattern you wish in the box and click{" "}
            <kbd>Generate</kbd>.<br />
            Clicking <kbd>Advanced</kbd> will let you automatically mirror the
            generated pattern for the desired number of ponies and whether or
            not you wish to include the alternative format.
            <br />
            <br />
            Clicking <kbd>Bias</kbd> will let you choose between different ways
            of biasing the pattern.
            <br />
            Selecting <kbd>Bias Direction</kbd> will default to biasing the
            generation to maintain the direction of the pattern.
            <br />
            Selecting <kbd>Bias Switch Direction</kbd> will bias the generation
            to switch the direction of the pattern.
            <br />
            Selecting <kbd>Bias Adjacent</kbd> will bias the generator to stay
            within 2 circles of the previous circle.
            <br />
            <br />
            Clicking <kbd>Copy</kbd> will copy the generated pattern(s) to your
            clipboard.
            <br />
          </p>
        </HelpButton>
        <div id="advanced" style={{ display: "none" }}>
          <p>Advanced</p>
          <label htmlFor="numPonies">Number of Ponies</label>
          <br />
          <Button
            additionalStyles="btn-secondary"
            onClick={handleNumPoniesDecrement}
            data-attr="decrement"
          >
            -
          </Button>
          <input
            className="m-2 rounded bg-gray-700 p-2"
            type="number"
            value={numPonies}
            onChange={handleNumPoniesChange}
            data-attr="numPonies"
          />
          <Button
            additionalStyles="btn-secondary"
            onClick={handleNumPoniesIncrement}
            data-attr="increment"
          >
            +
          </Button>
          <br />
          <div>
            <input
              id="includeAlternative"
              className="m-2 h-4 w-4 rounded border-gray-300 p-1 text-green-800 focus:ring-green-800 disabled:opacity-50"
              type="checkbox"
              checked={includeAlternative}
              onChange={() => setIncludeAlternative(!includeAlternative)}
              disabled={!includeAlternativeEnabled}
              data-attr="includeAlternative"
            />
            <label htmlFor="includeAlternative">
              Include Alternative pattern format
            </label>
          </div>
          <div>
            <input
              id="onlyAlternative"
              className="m-2 h-4 w-4 rounded border-gray-300 p-1 text-green-800 focus:ring-green-800"
              type="checkbox"
              checked={onlyAlternative}
              onChange={() => setOnlyAlternative(!onlyAlternative)}
              data-attr="onlyAlternative"
            />
            <label htmlFor="onlyAlternative">Only Alternative</label>
          </div>
          <div>
            <input
              id="sinful"
              className="m-2 h-4 w-4 rounded border-gray-300 p-1 text-green-800 focus:ring-green-800"
              type="checkbox"
              checked={sinful}
              onChange={() => setSinful(!sinful)}
              data-attr="sinful"
            />
            <label htmlFor="sinful">Sinful</label>
          </div>
          <div>
            <input
              id="bias"
              className="m-2 h-4 w-4 rounded border-gray-300 p-1 text-green-800 focus:ring-green-800"
              type="checkbox"
              checked={bias}
              onChange={() => setBias(!bias)}
              data-attr="bias"
            />
            <label htmlFor="bias">Bias</label>
          </div>
          <div>
            <input
              id="biasDirection"
              className="m-2 h-4 w-4 rounded border-gray-300 p-1 text-green-800 focus:ring-green-800 disabled:opacity-50"
              type="checkbox"
              checked={biasDirection}
              onChange={() => setBiasDirection(!biasDirection)}
              disabled={!bias}
              data-attr="biasDirection"
            />
            <label htmlFor="biasDirection">Bias Direction</label>
          </div>
          <div>
            <input
              id="biasSwitchDirection"
              className="m-2 h-4 w-4 rounded border-gray-300 p-1 text-green-800 focus:ring-green-800 disabled:opacity-50"
              type="checkbox"
              checked={biasSwitchDirection}
              onChange={() => setBiasSwitchDirection(!biasSwitchDirection)}
              disabled={!bias || !biasDirection}
              data-attr="biasSwitchDirection"
            />
            <label htmlFor="biasSwitchDirection">Bias Switch Direction</label>
          </div>
          <div>
            <input
              id="biasAdjacent"
              className="m-2 h-4 w-4 rounded border-gray-300 p-1 text-green-800 focus:ring-green-800 disabled:opacity-50"
              type="checkbox"
              checked={biasAdjacent}
              onChange={() => setBiasAdjacent(!biasAdjacent)}
              disabled={!bias}
              data-attr="biasAdjacent"
            />
            <label htmlFor="biasAdjacent">Bias Adjacent</label>
          </div>
        </div>
        <pre
          className="m-8 rounded bg-gray-700 p-8"
          style={{ display: pattern.length > 0 ? "block" : "none" }}
        >
          {output.map((p) => (
            <span key={p}>
              {p}
              <br />
            </span>
          ))}
        </pre>
      </main>
    </>
  );
}
