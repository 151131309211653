import Button from "@/components/Button";
import Toast from "@/components/Toast";
import { useEffect, useState } from "react";

export default function CopyButton({ copyText }) {
  const [text, setText] = useState("");
  const [hasText, setHasText] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    if (copyText.length > 0) {
      if (copyText !== text) {
        setText(copyText);
        setIsCopied(false);
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      }
      setHasText(true);
    } else {
      setHasText(false);
    }
  }, [copyText, text, timeoutId]);

  async function copyTextToClipboard() {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  function handleCopyClick() {
    copyTextToClipboard()
      .then(() => {
        setIsCopied(true);
        setTimeoutId(setTimeout(() => setIsCopied(false), 10000));
      })
      .catch((err) => console.error(err));
  }

  return (
    <>
      <Button
        additionalStyles="bg-orange-600 text-white hover:bg-orange-500 disabled:bg-orange-900 disabled:text-black disabled:cursor-not-allowed focus:ring-orange-500 focus:ring-opacity-50 focus:outline-none focus:ring-2"
        onClick={handleCopyClick}
        disabled={!hasText}
      >
        Copy
      </Button>
      <Toast title="Clipboard" show={isCopied} setShow={isCopied}>
        Copied!
      </Toast>
    </>
  );
}
